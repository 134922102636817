var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getJumbotronData)?_c('section',{staticClass:"px-md-10 pa-5 d-flex flex-column justify-center heroBlock",style:(['z-index: 2', {
    backgroundImage: 'linear-gradient(180deg, rgba(37, 52, 66, 1) 0%, rgba(39, 170, 225, 1) 200%),url(' + '/assets/Home/BlockHero/pattern3.jpg',
    backgroundRepeat: 'repeat',
    backgroundBlendMode: 'multiply',
  }])},[_c('Particles',{class:{'largeScreen': _vm.$vuetify.breakpoint.xlOnly},attrs:{"id":"particles","particles-init":_vm.particlesInit,"particles-loaded":_vm.particlesLoaded,"options":_vm.options}}),_c('h1',{staticClass:"text-center text-body-1 text-sm-h6 pt-2 text-md-h6 text-lg-h4 text-xl-h4 font-weight-medium white--text",staticStyle:{"z-index":"2"}},[_vm._v(" "+_vm._s(_vm.getJumbotronData.title)+" ")]),_c('p',{class:[
      'lato-font-medium my-4 primary--text px-1 text-center',
      {
        'lato-text-md': _vm.$vuetify.breakpoint.mdOnly,
        'lato-text-lg': _vm.$vuetify.breakpoint.lgAndUp,
        'lato-text-sm': _vm.$vuetify.breakpoint.smAndDown
      }
    ],attrs:{"id":"subtitle"},domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.getJumbotronData.subtitle))}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
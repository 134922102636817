const customBreakPoints  = {
    thresholds: {
        // xs: 340,
        // sm: 540,
        // md: 800,
        // lg: 1280,
    },
// scrollBarWidth: 24,
};

export default customBreakPoints;
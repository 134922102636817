var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"value":_vm.dialog,"fullscreen":"","persistent":"","retain-focus":false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDialog()}}},[_c('v-card',[_c('div',{staticClass:"d-flex pt-6 px-6 justify-space-between",class:{
          'flex-column align-end': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('div',{staticClass:"order-md-3",staticStyle:{"padding-left":"14.4%"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{attrs:{"color":"black","size":"40px"}},[_vm._v(" mdi-close ")])],1)],1),_c('router-link',{staticClass:"mt-n5 order-md-1",class:{
            'mt-n15 mx-auto': _vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/assets/fairsharing-logo.svg","alt":"FAIRsharing logo"},on:{"click":function($event){return _vm.closeDialog()}}})]),_c('div',{staticClass:"order-sm-2",staticStyle:{"text-align":"center","margin":"0 auto 0 auto"}},[_c('h2',{staticClass:"primary--text"},[_vm._v(" Advanced filtering and searching for FAIRsharing records ")]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" Find out more about our Advanced Search in our "),_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"https://fairsharing.gitbook.io/fairsharing/how-to/advanced-search","target":"_blank"}},[_vm._v("gitbook documentation"),_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s("fa fa-link")+" ")])],1)])])],1),_c('v-card-title',[_c('div',{staticClass:"d-flex full-width"},[_c('TooltipComponent',{attrs:{"tool-tip-text":_vm.toolTipText,"text-colour":"black--text"}}),(!_vm.getEditDialogStatus)?_c('v-text-field',{ref:"inputRef",staticClass:"text-h5",attrs:{"clearable":"","full-width":"","outlined":"","hide-details":"","label":"Add Search text"},on:{"change":function($event){return _vm.updateSearchText($event)}}}):_c('v-text-field',{staticClass:"text-h5",attrs:{"clearable":"","full-width":"","outlined":"","hide-details":"","label":"Add Search text","value":_vm.getAdvancedSearchText},on:{"input":function($event){return _vm.updateSearchText($event)},"change":function($event){return _vm.updateSearchText($event)}}})],1)]),_c('v-card-text',[_c('QueryBuilderView',{attrs:{"is-dialog":_vm.dialog}})],1),_c('v-card-actions',{staticClass:"px-6 justify-space-between",class:{
          'flex-column align-center': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-btn',{staticClass:"white--text order-md-2",class:{
            'mb-3': _vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"color":"green","variant":"text","disabled":_vm.isContinue,"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '250'},on:{"click":function($event){return _vm.goToAdvancedSearch()}}},[_vm._v(" Proceed ")]),_c('v-btn',{staticClass:"white--text order-md-1 ml-0",attrs:{"color":"accent3","variant":"text","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '250'},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    //-- main color palette used for most parts of the app:
    primary: '#27aae1',
    secondary: '#253442',
    accent: '#006055',
    accent2: '#E67E22',
    accent3: '#6B1E1E',

    //-- record status color definition:
    dev_color: '#CB9221',
    uncertain_color: '#ADA3A3',
    ready_color: '#599C0F',
    deprecated_color: '#8a3737',

    //-- subjects domains tax tags color
    subject_color: '#E67E22',
    domain_color: '#6B1E1E',
    taxonomic_color: '#006055',
    tags_color: '#ADA3A3',

    //-- browse subject color level:
    subject_topLevel_1:'#a04545',
    subject_topLevel_2:'#1ba393',
    subject_topLevel_3:'#678bab',
    subject_topLevel_4:'#c6c6c6',

    // more customized color for various usage in the app:
    bg_record_card: '#f9f9f9',
    bg_standard_record_card: '#f9f9f9',
    bg_database_record_card: '#f9f9f9',
    bg_policy_record_card: '#f9f9f9',
    bg_collection_record_card: '#f0f5f9',
    bg_organisation_record_card: '#dfeef6',

    dark_gray:'#727272'
}